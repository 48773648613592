:root {
    --bs-body-bg: #f3f4f6;
}
body {
    font-family: "Inter Tight", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    font-size: 15px;
    line-height: 22px;
}
p {
    margin: 0;
    color: #4b5563;
}
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.grid {
    max-width: 1024px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: 320px minmax(0, 1fr);
    gap: 16px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 991.98px) {
        display: flex;
        flex-direction: column;
    }
    @media screen and (max-width: 429.98px) {
        margin-top: 24px;
        margin-bottom: 24px;
    }
}

.noPad429 {
    @media screen and (max-width: 429.98px) {
        padding-left: 0;
        padding-right: 0;
    }
}
