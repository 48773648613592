.title {
    padding: 10px 16px 0 16px;
}
.cvList {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.cvCard {
    padding: 16px;
    background-color: #f9fafb;
    border-radius: 6px;
    display: flex;
    gap: 16px;
    transition-duration: 0.25s;
    &:hover {
        background-color: #f3f4f6;
    }
    > img {
        width: 48px;
        height: 48px;
        object-fit: cover;
        object-position: center;
        display: block;
        border-radius: 50%;
        border: 2px solid white;
        flex-shrink: 0;
    }
    @media screen and (max-width: 575.98px) {
        flex-direction: column;
    }
}
.jobTitle {
    display: flex;
    justify-content: space-between;
    span {
        display: block;
        padding: 2px 8px;
        background-color: #e5e7eb;
        border-radius: 4px;
        font-weight: 500;
        font-size: 13px;
        height: 28px;
    }
}
.jobInfo {
    margin-top: 6px;
    color: #4b5563;
    display: flex;
    gap: 2px 16px;
    flex-wrap: wrap;
    svg {
        width: 15px;
        height: 15px;
    }
    > div {
        display: flex;
        align-items: center;
        gap: 6px;
        span {
            font-size: 13px;
            font-weight: 500;
        }
    }
}
.jobName {
}
.jobLocation {
    span {
        margin-left: -2.5px;
    }
}
.jobDate {
}
.jobDescription {
    margin-top: 12px;
    font-size: 14.5px;
    strong {
        font-weight: 600;
    }
}
