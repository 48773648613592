.container {
    margin-top: 16px;
    border-radius: 0.75rem;
    padding: 6px;
    background-color: white;
    h2 {
        padding: 10px 16px 0;
    }
    hr {
        opacity: 0.15;
    }
}
.listContainer {
    margin-top: 16px;
    padding: 2px 16px 6px;
    background-color: #f9fafb;
    border-radius: 6px;
    ul {
        li {
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgba(black, 0.15);
            span {
                &:first-of-type {
                    min-width: 45%;
                    color: #4b5563;
                }
                &:last-of-type {
                    text-align: right;
                }
            }
            &:last-of-type {
                border-bottom: 0;
            }
        }
    }
}
