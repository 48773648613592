.container {
    position: relative;
}

.imageWrapper {
    overflow: hidden;
    background-color: white;
    height: 216px;
    position: relative;
    border-radius: 0.75rem;
    overflow: hidden;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    > * {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    > img {
        object-position: center;
        object-fit: cover;
        border-radius: 0.75rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.profilePicWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 16px;
    background-image: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0));
    > img {
        width: 88px;
        height: 88px;
        border-radius: 50%;
        border: 2px solid white;
    }
}

.bioContainer {
    padding: 6px;
    background-color: white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
.bioTitle {
    padding-left: 16px;
    padding-right: 16px;
}
.descriptionContainer {
    margin-top: 16px;
    padding: 12px 16px 16px;
    background-color: #f9fafb;
    border-radius: 6px;
}
.skillsWrapper {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    span {
        padding: 4px 8px;
        background-color: #e5e7eb;
        border-radius: 4px;
        font-weight: 500;
        font-size: 13px;
    }
}
