.fade-transition {
    position: relative;
    width: 100%;
    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: 0;
    transform: translateY(20px);

    > main {
        > div {
            margin-top: 16px;
            background-color: white;
            padding: 6px;
            border-radius: 12px;
        }
    }
}

.visible {
    opacity: 1;
    transform: translateY(0);
}

.tab-toggle-container {
    display: flex;
    gap: 6px;
    width: 100%;
    height: 52px;
    padding: 6px;
    background-color: white;
    border-radius: 12px;
}
.tab-toggle-item {
    display: flex;
    width: 50%;
}
.tab-toggle-item-button {
    flex: 1;
    border-radius: 6px;
    font-weight: 600;
    color: #4b5563;
    background-color: white;
    border: 0;
    transition-duration: 0.25s;
    &__active,
    &:hover {
        background-color: #f9fafb;
    }
    &__active {
        color: #508bd7;
    }
}
