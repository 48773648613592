.container {
    margin-top: 16px;
    border-radius: 0.75rem;
    padding: 6px;
    background-color: white;
    h2 {
        padding: 10px 16px 0;
    }
    hr {
        opacity: 0.15;
    }
}
.bioContainer {
    margin-top: 16px;
    padding: 12px 16px 16px;
    background-color: #f9fafb;
    border-radius: 6px;
}
.linkContainer {
    a {
        display: flex;
        align-items: center;
        font-weight: 600;
        svg {
            width: 17px;
            height: 17px;
            margin-right: 8px;
            margin-top: 2px;
        }
    }
}
